<div class="disorder-banner" >
    <div class="DisBanner">
        <div class="banner_img">
            <img src="{{api.mediaURL}}{{wBanner}}" class="web">              
            <img src="{{api.mediaURL}}{{wBanner1}}" class="mob">
            
        </div>

        <div class="parent-container"> 
            <div class="col-md-6 titleSTxt">
            <h3>{{DTitle}}</h3> 
        </div> 
        </div> 
         

        <div class="bookNow">
            <div class="row d-flex justify-content-center">
                <div class="col-md-12">
                    <h3 class="text-center">Book Now</h3>
                    <div class="row d-flex justify-content-center">
                         <ng-container *ngFor="let InpItem of BookPages">
                            <div class="mb-2 col-md-10" *ngIf="InpItem==='Name=true'">
                              <input autocomplete="off" type="text" class="form-control" [(ngModel)]="fName" placeholder="Enter Name">
                            </div>
                            <div class="mb-2 col-md-10" *ngIf="InpItem==='Email=true'">
                                <input autocomplete="off" type="text" class="form-control" [(ngModel)]="fEmail"  placeholder="Enter Email">
                              </div>
                              <div class="mb-2 col-md-10" *ngIf="InpItem==='PhoneNo=true'">
                                <input autocomplete="off" (keypress)="keyPress($event)" required type="text" pattern="[56789][0-9]{9}" class="form-control" [(ngModel)]="fPhoneNo" placeholder="Enter Phone No">
                                 
                              </div>
                              <div class="mb-2 col-md-10" *ngIf="InpItem==='City=true'">
                                <input autocomplete="off" type="text" class="form-control" [(ngModel)]="fCity" placeholder="Enter City">
                              </div>
                              <div class="mb-2 col-md-10" *ngIf="InpItem==='TestName=true'">
                                <input autocomplete="off" type="text" class="form-control" [(ngModel)]="fTestName" placeholder="Enter Test Name">
                              </div>
                              <div class="mb-2 col-md-10" *ngIf="InpItem==='Message=true'">
                                <textarea autocomplete="off" type="text" class="form-control" [(ngModel)]="fMessage" placeholder="Enter Message"></textarea>
                              </div>
                        </ng-container> 
                            <button type="submit" class="btn btn-primary" (click)="submit()" >Book Now</button>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="IDtabs" *ngIf="DisorderTab?.length">
    <div class="container">
        <div class="owl-carousel owl-theme tabCarousel">
            <div class="item" *ngFor="let TabItem of DisorderTab">  <!-- *ngIf="'t'+TabItem.id.active" -->
                <a style="cursor: pointer;"  id="{{'t'+TabItem.id}}" (click)="tab=TabItem.id">{{TabItem.tab_name}}</a>
            </div>
        </div>
      <!-- <ul>
        <li *ngFor="let TabItem of DisorderTab">
            <a style="cursor: pointer;" id="{{'t'+TabItem.id}}" (click)="tab=TabItem.id">{{TabItem.tab_name}}</a>
        </li>  
      </ul>  -->
    </div>
</section>

<div *ngFor="let TabItem of DisorderTab" class="container rest_container" >
    <!-- *ngIf='TabVisible:show()' -->
        <section  class="overview pt-40 testTooglecontent " *ngIf="tab===TabItem.id" id="{{'t'+TabItem.id}}">
            <div class="container">
                <div class="row tabsContent">
                    <div class="col-md-3" [innerHTML]="TabItem.description1" ></div>
                    <div class="col-md-9" [innerHTML]="TabItem.description2"></div>
                </div>
            </div>
        </section>
</div>

<!-- <section *ngFor="let Item of Disorder" >
    <div class="container" *ngIf="tab===0">
        <div class="row tabsContent">
            <div class="col-md-3" ><h3 class="pb-20">{{DName}}</h3></div>
            <div class="col-md-9" [innerHTML]="Description"></div>
        </div>
    </div>
</section> -->

<!-- conternt area -->
<!-- <div class="contentArea">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div [innerHTML]="Description"></div> 
            </div> 
        </div>
    </div>
</div>  -->

<!-- Health Package -->

  

  <!-- Health Package -->
<div class="health_package" *ngIf="LabTests?.length">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="center-heading mb-3">
                    <h5 class="sub-heading font28 w600"  style="float: left">Related Test </h5> 
                    <a href="/labtest" class="btn btn-primary-sm" style="float: right" > View All &nbsp;<i class="fa fa-arrow-circle-right"></i></a>
                </div>
                
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="healthSlider owl-carousel owl-theme">
                    <div *ngFor="let LabTestsItem of LabTests;let i = index;" class="item">
                        <div class="health_card">
                            <h4>{{LabTestsItem.name}}</h4>
                            <div class="test_body">
                                <!-- <p class=""><i class=" fa fa-exclamation-circle pr-10"></i>No special preparation required</p> -->
                                <p class=""><i class="fa fa-cog pr-10"></i>Parameter(s) Covered : <span>{{LabTestsItem.parameters_count}}</span></p>
                                <p class=""><i class="fa fa-file-text pr-10"></i>Report : <span>{{LabTestsItem.tat}} Hrs.</span></p>
                                <p>₹ {{LabTestsItem.test_rate}}</p>
                              </div>
                             
                              <div class=""> 
                                  
                                  <a class="pac_details_view" href="/labtest/{{getURLTxt(LabTestsItem.name)}}/{{LabTestsItem.test_id}}" > View Detail </a>   
                                   
                              </div>
                            <!-- cart -->
                            <div class="addToCart">
                                <img src="assets/images/cart-add-icon.png" *ngIf="!isInCart(i)"  (click)="add(i)" id="a{{i}}">
                            </div>
                        </div>
                    </div>
                     
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="health_package" *ngIf="LabTestsPackage?.length">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="center-heading mb-3">
                    <h5 class="sub-heading font28 w600"  style="float: left">Related Packages </h5> 
                    <a href="/labtest" class="btn btn-primary-sm" style="float: right" > View All &nbsp;<i class="fa fa-arrow-circle-right"></i></a>
                </div>
                
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="healthSlider owl-carousel owl-theme">
                    <div *ngFor="let LabTestsItem of LabTestsPackage; let i = index;" class="item">
                        <div class="health_card">
                            <h4>{{LabTestsItem.name}}</h4>
                             
                            <div class="test_body">
                                <!-- <p class=""><i class=" fa fa-exclamation-circle pr-10"></i>No special preparation required</p> -->
                                <p class=""><i class="fa fa-cog pr-10"></i>Parameter(s) Covered : <span>{{LabTestsItem.parameters_count}}</span></p>
                                <p class=""><i class="fa fa-file-text pr-10"></i>Report : <span>{{LabTestsItem.tat}} Hrs.</span></p>
                                <p>₹ {{LabTestsItem.test_rate}}</p>
                              </div>
                             
                              <div class=""> 
                                  
                                  <a class="pac_details_view"   href="/labtest/{{getURLTxt(LabTestsItem.name)}}/{{LabTestsItem.test_id}}" > View Detail </a>   
                                   
                              </div>

                            <!-- cart -->
                            <div class="addToCart">
                                <img src="assets/images/cart-add-icon.png" *ngIf="!isInCart(i)"  (click)="add(i)" id="a{{i}}" >
                            </div>
                        </div>
                    </div>
                     
                </div>
            </div>
        </div>
    </div>
  </div>
<!-- <div class="container">
  <p class="page_header_dynamics">{{util.translate('FAQs')}}</p>
  <div *ngIf="!loaded">
    <ngx-skeleton-loader *ngFor="let item of [1,2,3,4,5,6,7,8,9]"
      [theme]="{ 'border-radius': '5px', height: '10px',width:'100%'}"></ngx-skeleton-loader>
  </div>
  <p [innerHTML]="getContent()" *ngIf="loaded"></p>
</div> -->

 
<!-- Lifecycle Disorder -->
<div class="lifecycle">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="header">
                    <h2>Lifecycle Disorder</h2>
                    <a href="/disorder" class="btn btn-primary-sm">View All  &nbsp;<i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" *ngIf="Disorder?.length">
                <div class="lifecycle_Slider owl-carousel owl-theme">
                   
                     
                      <div class="item" *ngFor="let item of Disorders" > 
                        <a href="/disorder/{{item.Name}}">
                            <div class="diseas_img">
                                <img src="{{api.mediaURL}}{{item.Path}}">
                            </div>
                            <p>{{item.Name}}</p>
                         </a>
                      </div>  
  
                </div>
            </div>
        </div>
    </div>
  </div>  
