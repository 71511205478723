import { Component, OnInit,ChangeDetectorRef } from '@angular/core'; 
import { ActivatedRoute } from 'node_modules/@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util.service';
import { CartService } from 'src/app/services/cart.service';
import { ModalDirective } from 'angular-bootstrap-md'; 
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { Router, NavigationExtras } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-disorder-details',
  templateUrl: './disorder-details.component.html',
  styleUrls: ['./disorder-details.component.scss']
})
export class DisorderComponent implements OnInit {
  content: any;
  loaded: boolean;
  disordername:any;
  Disorder: any[]=[];
  Disorders: any[]=[];
  DisorderTab: any[]=[];
  wBanner:any;
  wBanner1:any;
  DName:any;
  DTitle:any='';
  Description:any;
  DisOrdId:any;
  TabVisible:any=true;
  tab: any = 0;
  BookPages:any[]=[]; 
  fName:any='';
  fEmail:any='';
  fPhoneNo:any='';
  fCity:any='';
  fTestName:any='';
  fMessage:any='';
  fdate:any = moment().format('YYYY-MM-DD');
  LabTest:any;
  LabTests:any[]=[];
  LabTestsProfile:any[]=[];
  LabTestsPackage:any[]=[];
  RelatedTest:any;
  LabTestCart: any[] = [];
  dummyLabTestCart: any[] = [];

  sameCart: any[] = [];
  variantIndex: any = '';
  images: any[] = [];
  lists: any;
  carts: any[] = [];
  userCart: any[] = [];
  productName: any = '';
  Tests:any []=[];
  RelatedProfile:any='';

  sameProduct: boolean = false;
  removeProduct: boolean = false;
 


  constructor( 
    private router: Router,
    private route: ActivatedRoute, 
    public api: ApiService,
    public cart: CartService, 
    private chMod: ChangeDetectorRef,
    public util: UtilService
  ) {

    if (this.route.snapshot.paramMap.get('disordername')) {
      this.disordername = this.route.snapshot.paramMap.get('disordername'); 
      
    }
    //this.getDisorderPage();

    const param = { 
      name:this.disordername,
      id:1
    }; 
    this.util.start();
    this.api.post('disorder/getDisorderDetails', param).then((data: any) => {  
      console.log(data);
      if (data && data.status === 200 && data.data.length > 0) {
        const DataInfo=data.data[0];
        this.util.stop();
        this.RelatedTest  = DataInfo.RelatedTest;
        this.RelatedProfile= DataInfo.RelatedProfile;

        this.Disorder = []; 
        console.log('ddddddddddddddddddddddddddd------ddd--'+this.RelatedTest);
        //data.data = data.data.filter(x => x.status === '1');
        data.data.forEach(async (element) => {
          element.name = parseFloat(element.name);
          this.DisOrdId = element.id;

          //this.api.bannerURL+ 
          this.DName = element.Name;
          this.DTitle =  element.Title;
          this.wBanner = element.ImagePath;
          this.wBanner1 = element.ImagePath1;
          this.Description = element.Description;
          this.BookPages = element.BookPages.split(",");
          //this.RelatedTest  = element.RelatedTest; 
          this.Disorder.push(data.data);
          //console.log('==================== name === '+this.BookPages[1] );   
        }); 

        this.getDisorderPageTab();
        this.getLabTest(this.disordername,this.RelatedTest,this.RelatedProfile);

        //this.getBanners(info);
        //console.log('===================='+this.Disorder);
         
      } else {
        this.Disorder = [];  
      }
    }, error => {
      console.log(error);
      this.loaded = true;
      this.util.errorMessage(this.util.translate('Something went wrong'));
    }).catch(error => {
      console.log(error);
      this.loaded = true;
      this.util.errorMessage(this.util.translate('Something went wrong'));
    });

    

    

    this.getDisorder();

   
    

  }

  getURLTxt($scope)
  {
    return $scope.replace(',','').replace(/(\(.*\))/g, '').replace(' ' ,'-').replace('/','');
    
  }

  

  //only number will be add
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  
  ngOnInit(): void {
  }

  toggle_visibility1(event){
    console.log("dddddddddddddd - "+event.id);
    this.TabVisible = true;
  }

  /* toggle_visibility(event)
      { 
        var id = 't'+event.id;
     
        var element = document.getElementsByClassName("testTooglecontent");
        for (var i = 0; i < element.length; i++) 
        {
            element[i].classList.remove("show");
            //this.TabVisible="";
        }
      
        var element1 = document.getElementById(id);
        element1.classList.add("show");
        console.log("dddddddddddddd -= "+ id);
        this.TabVisible="show";

      }
 */
      
  getContent() {
    return this.content;
  }

  getDisorderPage() {
    const param = { 
      name:this.disordername,
      id:1
    }; 
    this.util.start();
    this.api.post('disorder/getDisorderDetails', param).then((data: any) => {  
      console.log(data);
      if (data && data.status === 200 && data.data.length > 0) {
        this.util.stop();
        this.Disorder = []; 
        console.log('ddddddddddddddddddddddddddd');
        //data.data = data.data.filter(x => x.status === '1');
        data.data.forEach(async (element) => {
          element.name = parseFloat(element.name);
          this.DisOrdId = element.id;
          //this.api.bannerURL+ 
          this.DName = element.Name;
          this.wBanner = element.ImagePath;
          this.wBanner1 = element.ImagePath1;
          this.Description = element.Description;
          this.BookPages = element.BookPages.split(",");
          this.RelatedTest  = element.RelatedTest;

          this.Disorder.push(data.data);
          console.log('==================== name === '+this.BookPages[1] ); 
          this.getDisorderPageTab();
          
        }); 
        //this.getBanners(info);
        //console.log('===================='+this.Disorder);
         
      } else {
        this.Disorder = [];  
      }
    }, error => {
      console.log(error); 
      this.Disorder = []; 
    }).catch(error => {
      console.log(error); 
      this.Disorder = []; 
    });
  }

  getDisorderPageTab() {
    const param = { 
      id:this.DisOrdId
    }; 
    this.api.post('disordertab/getDisorderDetailsTab', param).then((data: any) => { 
      console.log(data);
      if (data && data.status === 200 && data.data.length > 0) {
        //this.DisorderTab = []; 
        console.log('ddddddddddddddddddddddddddd');
        //data.data = data.data.filter(x => x.status === '1');
        data.data.forEach(async (element) => {
          element.name = parseFloat(element.name); 
          this.tab = data.data[0].id;

          this.DisorderTab=data.data;
          this.tabCarousel();


          //console.log('==================== name '+this.wBanner ); 
          //this.toggle_visibility(1);
        }); 
        //this.getBanners(info);
        console.log('===================='+this.DisorderTab);
         
      } else {
        this.DisorderTab = [];  
      }
    }, error => {
      console.log(error); 
      this.DisorderTab = []; 
    }).catch(error => {
      console.log(error); 
      this.DisorderTab = []; 
    });
  }


  getDisorderPagec() {
    const param = {  
      name:this.disordername,
      id:1
    };


    this.api.post('disorder/getDisorderDetails',param).then((data: any) => {
      console.log(data);
      if (data && data.status === 200 && data.data.length) {
        this.Disorder = data.data; 
        //this.Disorder = data.data.filter(x => x.status === '1');
      }
    }, error => {
      console.log(error);
      this.util.errorMessage(this.util.translate('something went wrong'));
    }).catch((error: any) => {
      console.log(error);
      this.util.errorMessage(this.util.translate('something went wrong'));
    });
  }


  submit() {
    //console.log('contact' );
    if (this.fName === '' || this.fPhoneNo === '') {
      this.util.errorMessage(this.util.translate('All Fields are required'));
      return false;
    }
     
    const para = {
      Name: this.fName,
      Email: this.fEmail,
      PhoneNo:this.fPhoneNo,
      City:this.fCity,
      TestName:this.fTestName, 
      Message:this.fMessage,
      Status:0,
      Disorder:this.disordername 
    };
    //this.util.start();
    this.api.post('bookingdisorder/save', para).then((data: any) => {
      //this.util.stop();

      if(this.fEmail!=='')
      { 
          const param = {
            email: this.fEmail
          };
          this.api.post('users/BookingResponse', param).then((data: any) => {
            //console.log(data);
            //this.router.navigate(['thanks']);


          }, error => {
            console.log(error);
          });
      }
      this.fName='';
      this.fEmail='';
      this.fPhoneNo='';
      this.fCity='';
      this.fTestName='';
      this.fMessage='';

      this.router.navigate(['thanks']);


      if (data && data.status === 200) {

        this.success();
      } else {
        this.util.errorMessage(this.util.translate('Something went wrong'));
      }
    }, error => {
      console.log(error);
      this.util.stop();
      this.util.errorMessage(this.util.translate('Something went wrong'));
    });
  }


  
  getDisorder() {
    this.api.get('disorder/getDisorder').then((data: any) => {
      console.log(data);
      if (data && data.status === 200 && data.data.length) {
        this.Disorders = data.data;
        this.DisplayDisorder();
        //this.Disorder = data.data.filter(x => x.status === '1');
      }
    }, error => {
      console.log(error);
      this.util.errorMessage(this.util.translate('something went wrong'));
    }).catch((error: any) => {
      console.log(error);
      this.util.errorMessage(this.util.translate('something went wrong'));
    });
  }


  DisplayDisorder()
  {
    setTimeout(() => {
      
      // Life cycle slider
    $('.lifecycle_Slider').owlCarousel({
      loop:false,
      nav:true,
      autoplay:true,
      autoplayTimeout:4000,
      dots: false,
      lazyLoad: true,
      smartSpeed:3000,
      responsive:{
          0:{
              items:2
          },
          600:{
              items:5
          },
          1000:{
              items:7
          }
      }
    });

    }, 500);

    
  }

  getLabTest(disordername,RelatedTest,RelatedProfile){
    const param = {
      id:0,
      locationId:localStorage.getItem('lablocation'),
      disordername: disordername,
      RelatedTest :RelatedTest,
      RelatedProfile:RelatedProfile
    };
    console.log("param-------------------------------"+RelatedTest);
    this.api.post('labtest/filtertest',param).then((data: any) => {
      if (data && data.status === 200 && data.data.length) {
        console.log("related data-------------------------------"+data.data);
        this.LabTests = data.data.filter(x => x.isPackage === 'false').filter(x => x.isProfile === 'false');;  
        //this.LabTests = data.data 
        this.LabTestsPackage = data.data.filter(x => x.isPackage === 'true');  
        this.LabTestsProfile = data.data.filter(x => x.isProfile === 'true');
        data.data.forEach(element => {
          if (this.cart.itemId.includes(element.id)) {
            const index = this.cart.cart.filter(x => x.id === element.id);
            console.log('->index->', index);
            if (index && index.length) {
              element['quantiy'] = index[0].quantiy;
              element['selectedItem'] = index[0].selectedItem;
            } else {
              element['quantiy'] = 0;
              element['selectedItem'] = [];
            }
          } else {
            element['quantiy'] = 0;
            element['selectedItem'] = [];
          }
        });
        this.LabTestCart = data.data; 
        this.dummyLabTestCart = data.data;
        this.chMod.detectChanges();

        this.DisplayLabTest();
        //this.blogs = data.data.filter(x => x.status === '1');
      }
    }, error => {
      console.log(error);
      this.util.errorMessage(this.util.translate('something went wrong'));
    }).catch((error: any) => {
      console.log(error);
      this.util.errorMessage(this.util.translate('something went wrong'));
    });
  }

  DisplayLabTest(){

    setTimeout(() => {
      
      // Health package slider
    $('.healthSlider').owlCarousel({
      loop:true,
      nav:true,
      margin: 15,
      autoplay:true,
      autoplayTimeout:4000,
      dots: false,
      lazyLoad: true,
      smartSpeed:3000,
      responsive:{
          0:{
              items:1
          },
          600:{
              items:2
          },
          1000:{
              items:4
          }
      }
    });

    }, 500);

     


  }



  success() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });

  }

  tabCarousel(){
    setTimeout(() => {
      $('.tabCarousel').owlCarousel({
        loop:false,
        margin:0,
        nav:true,
        dots: false,
        autoWidth: true,
        responsive:{
            0:{
                items:2
            },
            600:{
                items:3
            },
            1000:{
                items:5
            }
        }
    })
  }, 100);
  }
   

  add(index) {
    const uid = localStorage.getItem('uid');
    console.log('uid', localStorage.getItem('uid'));
    console.log('Item - ', index + "  Details" + this.LabTestCart[index]);
    console.log('Item End ');

    //document.getElementById("a"+index).style.display='none';
    //document.getElementById("r"+index).style.display='block';
    
          this.LabTestCart[index].quantiy = 1;
          this.cart.addItem(this.LabTestCart[index]);
          this.ItemAddedAlerts(); 
          this.chMod.detectChanges(); 
  }


  removeQ(index) {

    if (index < 0) {
      console.log('negative items');
      this.cart.cart = [];
      localStorage.removeItem('userCart');
      window.location.reload();
    }
     
    this.LabTestCart[index].quantiy = 0;
    this.cart.removeItem(this.LabTestCart[index].id);
    this.chMod.detectChanges();
    document.getElementById("a"+index).style.display='block';
    document.getElementById("r"+index).style.display='none';
     
  }

   

  getDate(item) {
    return moment(item).format('DD');
  }

  getMonth(item) {
    return moment(item).format('MMM');
  }

  addQSame(index) {
    this.sameCart[index].total = this.sameCart[index].total + 1;
  }

  isInCart(index)
  {
    if(this.LabTestCart[index].quantiy>0)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  async presentAlertConfirm() {
    console.log('present alert to ask!');
    Swal.fire({
      title: this.util.translate('Warning'),
      text: this.util.translate(`you already have item's in cart!`),
      icon: 'error',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: this.util.translate('Clear cart'),
      backdrop: false,
      background: 'white'
    }).then(status => {
      if (status && status.value) {
        console.log('clear');
        this.cart.clearCart();
      }
    });

  }
  
  async ItemAddedAlerts() {
    console.log('present alert to ask!');
    Swal.fire({
      title: this.util.translate('Successfully'),
      text: this.util.translate(`Test added successfully in cart!`),
      icon: 'success',
      showCancelButton: true,
      showConfirmButton: true, 
      confirmButtonText: this.util.translate('View Cart'),
      cancelButtonText: "Continue Shopping",
       
      backdrop: false,
      background: 'white'
    }).then(status => {
      if (status && status.value) {
        console.log('clear');
        this.router.navigate(['/show-cart']);
        //this.cart.clearCart();
      }
    });

  }




}
