
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DisorderRoutingModule } from './disorder-details-routing.module';
import { DisorderComponent } from './disorder-details.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [DisorderComponent],
  imports: [
    CommonModule,
    DisorderRoutingModule,
    SharedModule
  ]
})
export class DisorderModule { }